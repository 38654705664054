import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

const OtherPodcasts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulPodcast(sort: { fields: date, order: DESC }) {
          nodes {
            slug
            title
            description
            date(formatString: "DD MMMM YYYY")
            bankerImage {
              file {
                url
              }
            }
          }
        }
      }
    `
  );

  return (
    <>
      <div className='otherPodcasts-container'>
        <div className='otherPodcasts-otherPodcast'>Other podcasts</div>
        {Object.keys(data.allContentfulPodcast.nodes.slice(0, 5)).map(i => {
          return (
            <Link
              to={`/podcast/${data.allContentfulPodcast.nodes[i].slug}`}
              style={{ textDecoration: 'none', color: '#000' }}
              className='otherPodcasts-link'
            >
              <div className='otherPodcasts-tab'>
                <div className='otherPodcasts-image-div'>
                  <img
                    src={
                      data.allContentfulPodcast.nodes[i].bankerImage.file.url
                    }
                    alt='image'
                    className='otherPodcasts-image'
                  />
                </div>
                <div className='otherPodcasts-details'>
                  <div className='otherPodcasts-title'>
                    {data.allContentfulPodcast.nodes[i].title}
                  </div>
                  <div className='otherPodcasts-description'>
                    {data.allContentfulPodcast.nodes[i].description.slice(
                      0,
                      33
                    ) + '....'}
                  </div>
                  <div className='otherPodcasts-postedon'>
                    {data.allContentfulPodcast.nodes[i].date}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default OtherPodcasts;
