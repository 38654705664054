import React from 'react';

const PodCastHero = data => {
  return (
    <>
      <div className='podcastHero-container'>
        {/* Hero Area  */}
        <div className='podcastHero-div'>
          {/* Left Side of Hero Area  */}
          <div className='podcastHero-left'>
            <div className='podcastHero-title'>{data.data.title}</div>
            <div className='podcastHero-description'>
              {data.data.description}
            </div>
            <div className='podcastHero-postedOn'>
              Posted On: {data.data.date}
            </div>
          </div>
          {/* Right Side of Hero Area  */}
          <div className='podcastHero-right'>
            <div className='podcastHero-mobile'>
              <div className='podcastHero-speakerImg-div'>
                <img
                  src={data.data.bankerImage.file.url}
                  alt='image'
                  className='podcastHero-speakerImg'
                />
              </div>
              <div className='podcastHero-aboutSpeaker-details'>
                <div style={{ textAlign: 'center', width: 'fit-content' }}>
                  <div className='podcastHero-aboutSpeaker-name'>
                    {data.data.speakersName}
                  </div>
                  <div className='podcastHero-aboutSpeaker-company'>
                    {data.data.speakersCompany}
                  </div>
                  <div className='podcastHero-aboutSpeaker-designation'>
                    {data.data.speakersDesignation}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PodCastHero;
