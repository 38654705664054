import React from 'react';
import { graphql, Link } from 'gatsby';
import SpotifyPlayer from 'react-spotify-player';
import Layout from '../components/Layout';
import divider from '../images/divider-colored.svg';
import PodCastHero from '../components/PodcastHero';
import OtherPodcasts from '../components/OtherPodcasts';

export const query = graphql`
  query($slug: String!) {
    contentfulPodcast(slug: { eq: $slug }) {
      title
      slug
      episodeNumber
      description
      thumbnailImage {
        file {
          url
        }
      }
      date(formatString: "DD MMMM YYYY")
      link
      speakersInfo {
        childMarkdownRemark {
          html
        }
        speakersInfo
      }
      transcript {
        childMarkdownRemark {
          html
        }
        transcript
      }
      bankerImage {
        title
        file {
          url
        }
      }
      speakersName
      speakersCompany
      speakersDesignation
    }
  }
`;

const PodCast = props => {
  return (
    <div className='podcast'>
      <Layout isWithoutHero={true}>
        <div className='podcast-page'>
          <PodCastHero data={props.data.contentfulPodcast} />
          {/* About the speaker  */}
          <div className='podcast-page-below-hero'>
            <div className='podcast-page-column'>
              {/* Spotify Player  */}
              <div className='podcast-page-player'>
                <iframe 
                  allowTransparency="true"
                  frameborder="0"
                  height="234px"
                  width="100%"
                  src={`https://open.spotify.com/embed/episode/${props.data.contentfulPodcast.link}`}
                  allow="autoplay; clipboard-write; encrypted-media;"
                  loading="lazy"
                  title="Spotify">
                </iframe>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='podcastHero-aboutSpeaker'>
                  <div className='podcastHero-aboutSpeaker-title'>
                    About the speaker
                  </div>
                  <div
                    className='blog-post-content'
                    dangerouslySetInnerHTML={{
                      __html:
                        props.data.contentfulPodcast.speakersInfo
                          .childMarkdownRemark.html,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <OtherPodcasts
                img={props.data.contentfulPodcast.bankerImage.file.url}
              />
            </div>
          </div>
          {/* Transcript  */}
          {props.data.contentfulPodcast.transcript.transcript !== 'NA' ? (
            <div className='podcast-page-transcript'>
              <div className='title-prod'>
                <img src={divider} alt='divider' />
                <div>Transcript</div>
              </div>
              <div
                className='podcast-page-transcript-body'
                dangerouslySetInnerHTML={{
                  __html:
                    props.data.contentfulPodcast.transcript.childMarkdownRemark
                      .html,
                }}
              ></div>
            </div>
          ) : null}
        </div>
      </Layout>
    </div>
  );
};

export default PodCast;
